
import config from '@/config/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {},
})
export default class PortfolioOverview extends Vue {
  baseSpaceCdnUrl = config.baseSpaceCdnUrl;
  @Prop() portfolio!: any;
  video: any;
  fallbackImage?: string = '';

  created(): void {
    this.video = this.portfolio.videos[0];
    if (!this.video && this.portfolio.images.length > 0) {
      // use gallery first image as fallback
      this.fallbackImage = `${this.baseSpaceCdnUrl}${this.portfolio.images[0].lg}`;
    }
    // else if (!this.video && !this.portfolio.images.length) {
    //   // use cover image as last fallback
    //   this.fallbackImage = `${this.baseSpaceCdnUrl}${this.portfolio.coverImages[0].lg}`;
    // }
  }
}
