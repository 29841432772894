
import config from '@/config/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ContactAgentField from '@/components/contact-agent-field.vue';

@Options({
  components: {
    ContactAgentField,
  },
})
export default class PortfolioContactPerson extends Vue {
  baseSpaceCdnUrl = config.baseSpaceCdnUrl;
  @Prop() portfolio!: any;

  getName(): string {
    return `${this.portfolio.agent.firstName} ${this.portfolio.agent.lastName}`;
  }

  getPhone(): string {
    return `+${this.portfolio.agent.mobile.code}${this.portfolio.agent.mobile.number}`;
  }

  getAgency(): string {
    if (this.portfolio.agent.agency) {
      return `${this.portfolio.agent.agency.name}`;
    } else {
      return '';
    }
  }

  getRegNum(): string {
    if (this.portfolio.agent.regNum) {
      return `${this.portfolio.agent.regNum}`;
    } else {
      return '';
    }
  }

  getProfileImage(): string {
    if (this.portfolio.agent.profileImg) {
      return `${this.baseSpaceCdnUrl}${this.portfolio.agent.profileImg.sm}`;
    } else {
      return '';
    }
  }

  getBio(): string {
    if (this.portfolio.agent.bio) {
      return `${this.portfolio.agent.bio}`;
    } else {
      return '';
    }
  }
}
