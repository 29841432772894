
import config from '@/config/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ImageContainer from '@/components/image-container.vue';

@Options({
  components: {
    ImageContainer,
  },
})
export default class PortfolioCarousel extends Vue {
  @Prop() portfolio!: any;
  // carouselImages: any[] = [];
  coverImageUrl = '';
  baseSpaceCdnUrl = config.baseSpaceCdnUrl;

  created(): void {
    if (this.portfolio.coverImages && this.portfolio.coverImages.length > 0) {
      this.coverImageUrl = `${this.baseSpaceCdnUrl}${this.portfolio.coverImages[0].lg}`;
    }

    // this.portfolio.coverImages.forEach((image: any, index: number) => {
    //   this.carouselImages.push({
    //     _id: image._id,
    //     url: `${this.baseSpaceCdnUrl}${image.lg}`,
    //     active: index === 0 ? true : false,
    //   });
    // });

    // this.portfolio.images.forEach((image: any) => {
    //   this.carouselImages.push({
    //     _id: image._id,
    //     url: `${this.baseSpaceCdnUrl}${image.lg}`,
    //     active: false,
    //   });
    // });
  }
}
