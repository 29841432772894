
import { Options, Vue } from 'vue-class-component';
import Footer from '@/components/footer.vue';
import firebase from 'firebase/app';

@Options({
  components: {
    Footer,
  },
})
export default class PortfolioHome extends Vue {
  errorCode?: string;
  beforeCreate(): void {
    this.errorCode = this.$route.query.error as string;

    firebase.analytics().logEvent(firebase.analytics.EventName.PAGE_VIEW);
  }
}
