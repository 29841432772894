import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01fcf481"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column px-4 pb-4"
}
const _hoisted_2 = { class: "title mb-1" }
const _hoisted_3 = {
  key: 0,
  class: "sub"
}
const _hoisted_4 = {
  key: 0,
  class: "sub"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.title && _ctx.content)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
          (_ctx.subtitle)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.capitalize ? 'content capitalize' : 'content')
        }, [
          _createTextVNode(_toDisplayString(_ctx.content) + " ", 1),
          (_ctx.subcontent)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.subcontent), 1))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}