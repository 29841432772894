
import config from '@/config/config';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class PortfolioCalculate extends Vue {
  toolLoanEligibilityCalculatorUrl = '';
  created(): void {
    this.toolLoanEligibilityCalculatorUrl =
      config.toolsLoanEligibilityCalculatorUrl;
  }
}
