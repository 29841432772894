
import config from '@/config/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PortfolioLayoutContainer from './portfolio-layout-container.vue';

@Options({
  components: {
    PortfolioLayoutContainer,
  },
})
export default class PortfolioLayout extends Vue {
  baseSpaceCdnUrl = config.baseSpaceCdnUrl;
  @Prop() portfolio!: any;

  created(): void {
    if (this.portfolio.units && this.portfolio.units.length > 0) {
      this.portfolio.units[0].active = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onContactMe(data: any): void {
    const contactName = this.portfolio.agent.firstName;
    this.$emit(
      'on-contact-me',
      `Hi ${contactName}, I would like to know more about Project ${this.portfolio.name} - ${data}.`
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getMonthlyInstalmentConfig(monthlyInstalmentConfig: any) {
    if (monthlyInstalmentConfig) {
      return `* Estimated monthly instalments are calculated based on units' price (before discounts and promotional packages) with ${(
        (1 - monthlyInstalmentConfig.loanMargin) *
        100
      ).toFixed(0)}% downpayment, ${
        monthlyInstalmentConfig.annualInterestRate
      }% annual interest rate on ${
        monthlyInstalmentConfig.paybackMonth / 12
      } years loan.`;
    }
  }
}
