
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ContentField from '@/components/content-field.vue';
import ImageContainerWithModal from '@/components/image-container-with-modal.vue';
@Options({
  components: {
    ContentField,
    ImageContainerWithModal,
  },
})
export default class PortfolioLayoutContainer extends Vue {
  @Prop() unit: any;
  @Prop() currency: any;
  @Prop() layoutMeasurementUnit: any;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getUnitSizes(unit: any): string {
    if (this.layoutMeasurementUnit === 'SM') {
      if (
        unit.size &&
        unit.size.parcel &&
        unit.size.parcel.min &&
        unit.size.parcel.min.sm
      ) {
        return `${unit.size.parcel.min.sm.toLocaleString()} s.m.`;
      } else {
        return 'N/A';
      }
    } else {
      if (
        unit.size &&
        unit.size.parcel &&
        unit.size.parcel.min &&
        unit.size.parcel.min.sf
      ) {
        return `${unit.size.parcel.min.sf.toLocaleString()} s.f.`;
      } else {
        return 'N/A';
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getUnitPrice(unit: any): string {
    if (unit.price && unit.price.spa && unit.price.spa.min) {
      return `from ${this.currency} ${unit.price.spa.min.toLocaleString()}`;
    } else {
      return 'N/A';
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getUnitMonthlyLoan(unit: any): string {
    if (unit.monthlyInstalment) {
      return `${this.currency} ${unit.monthlyInstalment.toLocaleString()}`;
    } else {
      return 'N/A';
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onContactMe(unit: any): void {
    const message = `${unit.name}`;
    this.$emit('on-contact-me', message);
  }
}
