
import config from '@/config/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NoImageAvailable from './no-image-available.vue';
import ImageContainer from './image-container.vue';

@Options({
  components: {
    NoImageAvailable,
    ImageContainer,
  },
})
export default class ImageContainerWithModal extends Vue {
  @Prop() images?: any[];
  @Prop() id!: string;

  baseSpaceCdnUrl = config.baseSpaceCdnUrl;
  imageUrl?: string = '';

  created(): void {
    if (this.images && this.images.length > 0) {
      this.imageUrl = `${this.baseSpaceCdnUrl}${this.images[0].sm}`;

      this.images.forEach((image, index) => {
        if (index === 0) {
          image.active = true;
        }

        image.lg_full = `${this.baseSpaceCdnUrl}${image.lg}`;
      });
    }
  }
}
