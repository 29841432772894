import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1255a2fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 px-0" }
const _hoisted_3 = {
  key: 0,
  class: "map-container"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.fullMapUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("iframe", {
              width: "100%",
              height: "100%",
              loading: "lazy",
              frameborder: "1",
              style: {"border":"0.5px solid lightgray"},
              src: _ctx.fullMapUrl
            }, "\r\n        ", 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}