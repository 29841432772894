
import config from '@/config/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ImageContainer from '@/components/image-container.vue';

@Options({
  components: {
    ImageContainer,
  },
})
export default class PortfolioFacilities extends Vue {
  baseSpaceCdnUrl = config.baseSpaceCdnUrl;
  @Prop() portfolio!: any;
  imageUrl!: string;
  facilities: string[] = [];

  created(): void {
    if (this.portfolio.images && this.portfolio.images.length >= 3) {
      this.imageUrl = `${this.baseSpaceCdnUrl}${this.portfolio.images[2].lg}`;
    } else {
      this.imageUrl = '';
    }

    this.facilities = this.portfolio.facilities.items;
  }
}
