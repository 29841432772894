import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fab0c492"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative","width":"100%"} }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.galleryImages, (image) => {
        return (_openBlock(), _createElementBlock("div", {
          key: image._id,
          class: "col-4 col-md-3 col-lg-2 p-0"
        }, [
          _createElementVNode("div", {
            class: "image-preview-container",
            style: _normalizeStyle('background-image: url(' + image.sm + ')')
          }, null, 4)
        ]))
      }), 128))
    ])
  ]))
}