
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class PortfolioMap extends Vue {
  @Prop() portfolio!: any;
  fullMapUrl = '';

  created(): void {
    this.fullMapUrl = this.portfolio.fullMapUrl;
  }
}
