
import config from '@/config/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ImageContainer from '@/components/image-container.vue';

@Options({
  components: {
    ImageContainer,
  },
})
export default class PortfolioGallery extends Vue {
  @Prop() portfolio!: any;
  baseSpaceCdnUrl = config.baseSpaceCdnUrl;
  galleryImages: any[] = [];

  created(): void {
    this.portfolio.images.forEach((image: any) => {
      this.galleryImages.push({
        _id: image._id,
        sm: `${this.baseSpaceCdnUrl}${image.sm}`,
        lg: `${this.baseSpaceCdnUrl}${image.lg}`,
      });
    });
  }
}
