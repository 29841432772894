
import config from '@/config/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ContentField from '@/components/content-field.vue';
import ImageContainer from '@/components/image-container.vue';

@Options({
  components: {
    ContentField,
    ImageContainer,
  },
})
export default class PortfolioDetails extends Vue {
  baseSpaceCdnUrl = config.baseSpaceCdnUrl;
  @Prop() portfolio!: any;
  image?: string;

  created(): void {
    if (this.portfolio.images.length > 1) {
      this.image = `${this.baseSpaceCdnUrl}${this.portfolio.images[1].lg}`;
    } else {
      this.image = '';
    }
  }

  getCategory(): string {
    let categoryUnder = '';
    if (this.portfolio.category) {
      switch (this.portfolio.category.under) {
        case 'highrise':
          categoryUnder = 'Highrise';
          break;
        case 'landed':
          categoryUnder = 'Landed';
          break;
        case 'office':
          categoryUnder = 'Office';
          break;
        case 'shop':
          categoryUnder = 'Shoplot';
          break;
        case 'factory':
          categoryUnder = 'Factory';
          break;
        default:
          return this.portfolio.category.name;
      }

      if (this.portfolio.category.under && this.portfolio.category.name)
        return `${categoryUnder} / ${this.portfolio.category.name} `;
      else if (this.portfolio.category.name)
        return `${this.portfolio.category.name}`;
      else if (this.portfolio.category.under) return `${categoryUnder}`;
      else return '';
    } else {
      return '';
    }
  }

  getTitle(): string {
    switch (this.portfolio.title) {
      case 'res':
        return 'Residential';
      case 'comm-hda':
        return 'Commercial (HDA)';
      case 'comm':
        return 'Commercial';
      default:
        return this.portfolio.title;
    }
  }

  getDeveloper(): string {
    if (this.portfolio.teams && this.portfolio.teams.developer) {
      return this.portfolio.teams.developer;
    } else {
      return '';
    }
  }

  getTenure(): string {
    if (this.portfolio.tenure) {
      switch (this.portfolio.tenure.type) {
        case 'lease':
          return 'Leasehold';
        case 'free':
          return 'Freehold';
        default:
          return this.portfolio.tenure.type;
      }
    } else {
      return '';
    }
  }

  getStatus(): string {
    if (this.portfolio.phases && this.portfolio.phases.completed) {
      return 'Completed';
    } else if (
      this.portfolio.phases &&
      !this.portfolio.phases.completed &&
      this.portfolio.phases.launched
    ) {
      return 'Launched';
    } else {
      return 'Upcoming Launch';
    }
  }

  getExpectedCompletion(): string {
    if (this.portfolio.phases) {
      if (this.portfolio.phases.completed) {
        return this.portfolio.phases.completedUtcDate;
      } else {
        if (
          this.portfolio.phases.expectedCompletion &&
          this.portfolio.phases.expectedCompletion.year &&
          this.portfolio.phases.expectedCompletion.quarter
        ) {
          return `Q${this.portfolio.phases.expectedCompletion.quarter} / ${this.portfolio.phases.expectedCompletion.year}`;
        } else if (
          this.portfolio.phases.expectedCompletion &&
          this.portfolio.phases.expectedCompletion.year
        ) {
          return `${this.portfolio.phases.expectedCompletion.year}`;
        } else return '';
      }
    } else {
      return '';
    }
  }

  getUnitSizes(): string {
    const unitSize = this.portfolio.unitSize;
    const layoutMeasurementUnit = this.portfolio.layoutMeasurementUnit;
    if (unitSize) {
      if (layoutMeasurementUnit === 'SM') {
        if (
          unitSize.min &&
          unitSize.min.sm &&
          unitSize.max &&
          unitSize.max.sm
        ) {
          return `${unitSize.min.sm.toLocaleString()} s.m. - ${unitSize.max.sm.toLocaleString()} s.m.`;
        } else if (unitSize.min && unitSize.min.sm) {
          return `From ${unitSize.min.sm.toLocaleString()} s.m.`;
        } else if (unitSize.max && unitSize.max.sm) {
          return `Up to  ${unitSize.max.sm.toLocaleString()} s.m.`;
        } else {
          return '';
        }
      } else {
        if (
          unitSize.min &&
          unitSize.min.sf &&
          unitSize.max &&
          unitSize.max.sf
        ) {
          return `${unitSize.min.sf.toLocaleString()} s.f. - ${unitSize.max.sf.toLocaleString()} s.f.`;
        } else if (unitSize.min && unitSize.min.sf) {
          return `From ${unitSize.min.sf.toLocaleString()} s.f.`;
        } else if (unitSize.max && unitSize.max.sf) {
          return `Up to  ${unitSize.max.sf.toLocaleString()} s.f.`;
        } else {
          return '';
        }
      }
    } else {
      return '';
    }
  }

  getUnitPrice(): string {
    const price = this.portfolio.price;
    const currency = this.portfolio.currency;
    if (price) {
      if (price.spa && price.spa.min && price.spa.max) {
        return `${currency} ${price.spa.min.toLocaleString()} - ${currency} ${price.spa.max.toLocaleString()}`;
      } else if (price.spa && price.spa.min) {
        return `From ${currency} ${price.spa.min.toLocaleString()}`;
      } else if (price.spa && price.spa.max) {
        return `Up to ${currency} ${price.spa.max.toLocaleString()}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getFurnished(): string {
    if (this.portfolio.furnish) {
      switch (this.portfolio.furnish.in) {
        case 'full':
          return 'Fully Furnished';
        case 'partial':
          return 'Partial Furnished';
        default:
          return 'Not Furnished';
      }
    } else {
      return '';
    }
  }
}
