
import { Options, Vue } from 'vue-class-component';
import config from '@/config/config';
import axios from 'axios';
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import PortfolioCarousel from './portfolio-carousel.vue';
import PortfolioOverview from './portfolio-overview.vue';
import PortfolioGallery from './portfolio-gallery.vue';
import PortfolioMap from './portfolio-map.vue';
import PortfolioDetails from './portfolio-details.vue';
import PortfolioFacilities from './portfolio-facilities.vue';
import PortfolioContactPerson from './portfolio-contact-person.vue';
import PortfolioCalculate from './portfolio-calculate.vue';
import PortfolioLayout from './portfolio-layout.vue';
import firebase from 'firebase/app';

@Options({
  components: {
    Header,
    Footer,
    PortfolioCarousel,
    PortfolioOverview,
    PortfolioGallery,
    PortfolioMap,
    PortfolioDetails,
    PortfolioFacilities,
    PortfolioContactPerson,
    PortfolioCalculate,
    PortfolioLayout,
  },
})
export default class Portfolio extends Vue {
  baseApiUrl = config.baseApiUrl;
  baseSpaceCdnUrl = config.baseSpaceCdnUrl;

  portfolioCode: string | string[] = '';
  portfolio: any = null;
  isPreview = false;
  contactName = '';
  contact = '';
  whatsappLink = '';
  backgroundImage = '';

  created(): void {
    this.portfolioCode = this.$route.params.code;

    if (this.$route.path.endsWith('preview')) {
      this.isPreview = true;
      this.getPreviewPortfolio(
        this.portfolioCode,
        this.$route.query['token'] as string
      );

      firebase.analytics().logEvent(firebase.analytics.EventName.PAGE_VIEW, {
        projectCode: this.portfolioCode,
        previewMode: true,
      });
    } else {
      this.getPortfolio(this.portfolioCode);

      firebase.analytics().logEvent(firebase.analytics.EventName.PAGE_VIEW, {
        projectCode: this.portfolioCode,
        previewMode: false,
      });
    }
  }

  openWhatsappLink(message: string): void {
    open(`https://wa.me/${this.contact}?text=${message}`);
  }

  private getPortfolio(portfolioCode: string | string[]): void {
    axios
      .get(`${this.baseApiUrl}/projects/code/${portfolioCode}`)
      .then((result) => {
        this.portfolio = result.data.data;
        document.title = `${this.portfolio.name}'s Brochure`;
        if (
          this.portfolio.coverImages &&
          this.portfolio.coverImages.length > 0
        ) {
          this.backgroundImage = `${this.baseSpaceCdnUrl}${this.portfolio.coverImages[0].lg}`;
        }

        this.contactName = `${this.portfolio.agent.firstName}`;
        this.contact = `${this.portfolio.agent.mobile.code}${this.portfolio.agent.mobile.number}`;
      })
      .catch(() => {
        this.$router.push({ path: '/', query: { error: 'no-data' } });
      });
  }

  private getPreviewPortfolio(
    portfolioCode: string | string[],
    previewToken: string
  ): void {
    axios
      .get(`${this.baseApiUrl}/projects/code/${portfolioCode}/preview`, {
        headers: {
          Authorization: `Bearer ${previewToken}`,
        },
      })
      .then((result) => {
        this.portfolio = result.data.data;
        document.title = `[Preview] ${this.portfolio.name}'s Brochure`;

        if (
          this.portfolio.coverImages &&
          this.portfolio.coverImages.length > 0
        ) {
          this.backgroundImage = `${this.baseSpaceCdnUrl}${this.portfolio.coverImages[0].lg}`;
        }

        this.contactName = `${this.portfolio.agent.firstName}`;
        this.contact = `${this.portfolio.agent.mobile.code}${this.portfolio.agent.mobile.number}`;
      })
      .catch(() => {
        this.$router.push({ path: '/', query: { error: 'no-data' } });
      });
  }
}
