import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoImageAvailable = _resolveComponent("NoImageAvailable")!

  return (_openBlock(), _createElementBlock("div", {
    class: "image-container",
    style: _normalizeStyle('background-image: url(' + _ctx.imageUrl + ')')
  }, [
    (!_ctx.imageUrl)
      ? (_openBlock(), _createBlock(_component_NoImageAvailable, { key: 0 }))
      : _createCommentVNode("", true)
  ], 4))
}