
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class ContentField extends Vue {
  @Prop() title!: string;
  @Prop() subtitle?: string;
  @Prop() content!: string;
  @Prop() subcontent?: string;
  @Prop() capitalize?: boolean = false;
}
