
import { Options, Vue } from 'vue-class-component';
import config from './config/config';
import firebase from 'firebase/app';
import 'firebase/analytics';

@Options({})
export default class PortfolioHome extends Vue {
  nodeEnv = config.nodeEnv;

  beforeCreate(): void {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const firebaseConfig = require(`./config/firebase/firebase.${this.nodeEnv}.json`);
    firebase.initializeApp(firebaseConfig);
  }
}
